
@font-face {
	font-family: 'Halisa';
	src: url('../fonts/Halisa_Wide.otf') format('opentype');
	font-weight: 400,
}

@font-face {
	font-family: 'Halisa';
	src: url('../fonts/Halisa_WideBold.otf') format('opentype');
	font-weight: 700
}

@font-face {
	font-family: 'BTBrik';
	src: url('../fonts/BTBrikRegular.otf') format('opentype');
	font-weight: 400
}

@font-face {
	font-family: 'BTBrik';
	src: url('../fonts/BTBrikXL.otf') format('opentype');
	font-weight: 600
}

@font-face {
	font-family: 'BTBrik';
	src: url('../fonts/BTBrikXXL.otf') format('opentype');
	font-weight: 700
}

@font-face {
	font-family: 'BTBrikOblique';
	src: url('../fonts/BTBrikOblique.otf') format('opentype');
	font-weight: 400
}
@font-face {
	font-family: 'BTBrikOblique';
	src: url('../fonts/BTBrikXLOblique.otf') format('opentype');
	font-weight: 600
}
@font-face {
	font-family: 'BTBrikOblique';
	src: url('../fonts/BTBrikXXLOblique.otf') format('opentype');
	font-weight: 700
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,600&display=swap');